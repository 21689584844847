import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link } from 'react-router-dom';
import TableUsers from '#components/tables/users/tableUsers';
import User from './user';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import FormUser from '#components/forms/formUser/formUser';

const Users = ({ match }) => {
  return (
    <div data-testid="Notificaciones">
      <div className="section-header">
        <h2 className="title">Control de usuarios</h2>
        <Route
          exact
          path={`${match.url}/`}
          render={() => (
            <Link to="/panel/users/user" className="btn btn-primary">
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '.5rem' }} />
              Agregar usuario
            </Link>
          )}
        />
      </div>

      <div className="card">
        <div className="card-body">
          <Switch>
            <Route exact path={`${match.url}`} component={TableUsers} />
            <Route exact path={`${match.url}/user`} component={FormUser} />
            <Route exact path={`${match.url}/user/:id`} component={FormUser} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

Users.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Users.defaultProps = {
  match: {},
};

export default Users;
