import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from '../modalBase/modalBase';
import { isMobile } from 'react-device-detect';
import { isIFrame } from '#utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faStreetView, faClock, faUser, faSort } from '@fortawesome/free-solid-svg-icons';
import StreetMapsLink from '../../random/streetMapsLink/streetMapLink';
import FechaEnTexto from '../../random/fechaEnTexto/fechaEnTexto';
import styles from './infoStop.module.css';
import { roAPI } from '#utils/axiosAPI';
import ProgressBar from "@ramonak/react-progress-bar";
import stations from '../../../scenes/panel/stations/stations';

const InfoStopModal = ({ showModal, toggleModal, stop, routes, route }) => {
  const [time, setTime] = React.useState(null);
  const [direction, setDirection] = React.useState(0);
  const hardcodePercentage1 = 40;
  const hardcodePercentage2 = 18;

  React.useEffect(() => {
    async function getTime() {
      try {
        const res = await roAPI.get(`/stations/time/${route.id_city}/${route.group_id}/${stop.codigo}?direction=${direction}`);
        setTime(res);
      } catch (error) {
        //
      }
    }

    let interval;
    if (stop.codigo) {
      if (stop.twoWayStation == false) {
        if (stop.internal_order == 1) {
          setDirection(0);
        } else {
          setDirection(1);
        }
      }
      getTime();
      interval = setInterval(() => {
        if (stop.codigo) {
          getTime();
        }
      }, 30000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [stop, route, direction]);

  const lines = React.useMemo(() => {
    if (stop.codigo && showModal) {
      return stop.lineas.map(l => routes.find(x => x.group_id === l));
    }
    return [];
  }, [stop, showModal, routes]);

  const changeDirection = () => {
    if (direction == 0) {
      setDirection(1);
    } else {
      setDirection(0);
    }
  }

  return (
    <div>
      <ModalBase
        show={showModal}
        closeModal={toggleModal}
        headerStyle={{ paddingTop: '0' }}
        cardStyle={isMobile ? { width: '100%' } : { width: isIFrame() ? '40%' : '30%' }}
      >
        <div className="row">
          <div className="col-12">
            <p className={styles['header']} style={{ 'marginBottom': 0 }}>
              <FontAwesomeIcon icon={faInfoCircle} />
              <span>Información de la estación</span>
            </p>
            <StreetMapsLink
              customClass={styles['link']}
              text={
                <>
                  <FontAwesomeIcon icon={faStreetView} style={{ marginRight: '.5rem' }} />  [{stop.codigo}] <span>{stop.name}</span>
                </>
              }
              lat={stop.latitude}
              lng={stop.longitude}
            />

            {time && time.stations && (
              <div className='container'>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex flex-column">
                    <div className="btn-une col-12 text-center" style={{ borderRadius: '5px', padding: '5px', margin: '5px', backgroundColor: '#28a745' }}>
                      Desde <b>{time.stations.start.name}</b>
                    </div>
                    <div className="btn-une col-12 text-center" style={{ borderRadius: '5px', padding: '5px', margin: '5px' }}>
                      Hacia <b>{time.stations.end.name}</b>
                    </div>
                  </div>
                  {stop.twoWayStation && (
                    <div className="d-flex align-items-stretch">
                      <button className="btn btn-primary" style={{ height: '100%', marginLeft: '10px' }} onClick={changeDirection}>
                        <FontAwesomeIcon icon={faSort} />
                      </button>
                    </div>
                  )
                  }
                </div>
              </div>
            )}
            {time && (
              <div className='row' style={{ margin: '0', marginTop: '10px' }}>
                <p className='col-12 font-weight-bold'>Próximo</p>
                <p className='col-12'>
                  <FontAwesomeIcon icon={faClock} color='#0057b8' style={{ marginRight: '10px' }} />
                  <FechaEnTexto fecha={new Date().getTime() + time.vehicles[0].time * 1000} />
                </p>
                <p className='col-12 d-flex'>
                  <FontAwesomeIcon icon={faUser} color='#0057b8' className='mr-2' />
                  <span className='mr-2' style={{ marginTop: '-5px' }}>{hardcodePercentage1}%</span>
                  <div className='flex-grow-1'>
                    <ProgressBar bgColor={'#0057b8'} completed={hardcodePercentage1} customLabel=" " />
                  </div>
                </p>
                {time.vehicles[1].time && (
                  <>
                    <p className='col-12 font-weight-bold'>Siguiente</p>
                    <p className='col-12'>
                      <FontAwesomeIcon icon={faClock} color='#0057b8' style={{ marginRight: '10px' }} />
                      <FechaEnTexto fecha={new Date().getTime() + time.vehicles[1].time * 1000} />
                    </p>
                    <p className='col-12 d-flex'>
                      <FontAwesomeIcon icon={faUser} color='#0057b8' className='mr-2' />
                      <span className='mr-2' style={{ marginTop: '-5px' }}>{hardcodePercentage2}%</span>
                      <div className='flex-grow-1'>
                        <ProgressBar bgColor={'#0057b8'} completed={hardcodePercentage2} customLabel=" " />
                      </div>
                    </p>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </ModalBase >
    </div >
  );
};

InfoStopModal.propTypes = {
  showModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  stop: PropTypes.any,
  routes: PropTypes.array,
  route: PropTypes.any,
};

InfoStopModal.defaultProps = {
  showModal: false,
  toggleModal: f => f,
  stop: {},
  routes: [],
  route: {},
};

export default InfoStopModal;
