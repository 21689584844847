import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faCrosshairs,
  faInfoCircle,
  faBus,
  faRoute,
  faTimes,
  faBuilding,
  faStore,
  faCoins,
} from '@fortawesome/free-solid-svg-icons';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import LayoutOnWidth from '#components/HOCs/layoutOnWidth/layoutOnWidth';
import MainMap from '#components/maps/mainMap/mainMap';
import MapCardHeader from '#components/navbars/mapCardHeader/mapCardHeader';
import MapSidebar from '#components/navbars/mapSidebar/mapSidebar';
import MapRoutesSidebar from '#components/navbars/mapRoutesSidebar/mapRoutesSidebar';
import InfoRouteModal from '#components/modals/infoRoute/infoRoute';
import useModal from '#hooks/useModal';
import InfoBusModal from '#components/modals/infoBus/infoBus';
import TermsModal from '#components/modals/terms/terms';
import { isIFrame } from '#utils/utils';
import { roAPI } from '#utils/axiosAPI';
import AlertsHOC from '#components/HOCs/alertsHOC/alertsHOC';
import MapButtons from '#components/maps/extras/mapButtons/mapButtons';
import styles from './mapView.module.css';
import InfoStopModal from '#components/modals/infoStop/infoStop';
import InfoPOIModal from '#components/modals/infoPOI/infoPOI';
import LoadingBar from '#components/loading/loadingBar/loadingBar';
import InfoRPModal from '#components/modals/infoRP/infoRP';
import queryString from 'query-string';

const defaultRoute = {
  route_pois: [],
  route_configurations: [],
  route_unions: [],
};

const MapView = ({ terms, acceptTerms, city, cities, routes, trip, updateRoutes, clearTrip, loading, changeCity }) => {
  const [showSidebar, setShowSidebar] = React.useState(false);
  const toggleSidebar = () => setShowSidebar(prevState => !prevState);

  const [showRoutesSidebar, setShowRoutesSidebar] = React.useState(false);
  const toggleRoutesSidebar = () => setShowRoutesSidebar(prevState => !prevState);

  const [showPOIs, setShowPOIs] = React.useState(true);
  const toggleShowPOIs = () => setShowPOIs(prevState => !prevState);

  const [showBusses, setShowBusses] = React.useState(true);
  const toggleShowStops = () => setShowBusses(prevState => !prevState);

  const [route, setRoute] = React.useState(defaultRoute);
  const [routeTwoWays, setRouteTwoWays] = React.useState(false);
  const [busses, setBusses] = React.useState([]);
  const [bus, setBus] = React.useState({});
  const [stops, setStops] = React.useState([]);
  const [stop, setStop] = React.useState({});
  const [poi, setPoi] = React.useState({});
  const [rp, setRP] = React.useState({});

  const [showTerms, toggleTerms] = useModal(!terms);
  const [showInfoRoute, toggleInfoRoute] = useModal();
  const [showInfoBus, toggleInfoBus] = useModal();
  const [showInfoStop, toggleInfoStop] = useModal();
  const [showInfoPOI, toggleInfoPOI] = useModal();
  const [showInfoRP, toggleInfoRP] = useModal();

  const handleBusClose = () => {
    toggleInfoBus();
    // setBus({});
  };
  const handleStopClose = () => {
    toggleInfoStop();
    setStop({});
  };
  const handlePOIClose = () => {
    toggleInfoPOI();
    setPoi({});
  };
  const handleRPClose = () => {
    toggleInfoRP();
    setRP({});
  };

  React.useEffect(() => {
    setRoute(defaultRoute);
  }, [city]);

  React.useEffect(() => {
    async function fetchStops() {
      try {
        loading.set();
        const res = await roAPI.get(`/stations/app/${route.group_id}`);
        setStops(res);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    if (route.group_id) {
      fetchStops();
    }
  }, [city, route, loading]);

  // fetching busses interval
  React.useEffect(() => {
    async function fetchBussesRO() {
      try {
        const res = await roAPI.get(`/locations/${route.group_id}`);
        const twoWays = res.some(x => x.route_direction === 2);
        setBusses(res);
        setRouteTwoWays(twoWays);
      } catch (error) {
        // handle
      }
    }

    if (route.group_id) {
      fetchBussesRO();
    } else {
      return;
    }

    const interval = setInterval(() => {
      if (route.group_id) {
        fetchBussesRO();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [route]);

  // fetching routes of the city.
  React.useEffect(() => {
    async function fetchRoutesRO() {
      try {
        loading.set();
        const res = await roAPI.get(`/routes/city/${city}`);
        // type 1 = bigbang | type 2 = conduent
        const filtered = res.filter(x => !x.metadata.hasOwnProperty('active') || x.metadata.active);
        updateRoutes(filtered);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    fetchRoutesRO();
  }, [city, updateRoutes, loading]);

  // on Bus selected
  const handleBussClick = (bus, modal = true) => {
    setBus(bus);
    if (modal) {
      toggleInfoBus();
    }
  };

  React.useEffect(() => {
    if (bus.id) {
      const updated = busses.find(b => b.id === bus.id);
      if (updated) {
        setBus(updated);
      }
    }
  }, [busses, bus]);

  // fetching route
  async function onRouteClick(id) {
    const route = routes.find(r => r.group_id === id);
    setRoute(route);
    toggleRoutesSidebar();
    clearTrip();
  }

  function handleRouteClear() {
    setRoute(defaultRoute);
    setBusses([]);
    setBus({});
    toggleRoutesSidebar();
    setStop({});
  }

  // Location
  const handleLocation = map => {
    if (trip.selectedTrip || route.group_id) {
      map.resetBounds();
    } else if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          map.ref.leafletElement.flyTo([position.coords.latitude, position.coords.longitude], 16);
        },
        () => {
          map.resetBounds();
        },
      );
    } else {
      map.resetBounds();
    }
  };

  // Trip
  const handleOnTrip = () => {
    setRoute(defaultRoute);
    setBusses([]);
  };

  const selectedCity = React.useMemo(() => {
    if (cities.length && city) {
      const c = cities.find(x => x.id_city === city);
      if (c) {
        return c;
      }
    }
    return null;
  }, [cities, city]);

  /** ***************************************** */
  /** Compartir lineas */
  /** ***************************************** */

  const {
    location: { search },
    replace,
  } = useHistory();

  React.useEffect(() => {
    if (search && cities.length) {
      const params = queryString.parse(search);
      changeCity(params.ciudad);
    }
  }, [search, changeCity, cities]);

  React.useEffect(() => {
    if (search && routes.length) {
      const params = queryString.parse(search);
      const route = routes.find(r => r.group_id === params.linea);
      if (route) setRoute(route);
    }
  }, [search, routes]);

  React.useEffect(() => {
    if (search && routes.length && stops.length) {
      const params = queryString.parse(search);
      const estacion = stops.find(b => b.codigo === params.estacion);
      if (estacion) {
        setStop(estacion);
        toggleInfoStop();
      }
      replace('/estaciones');
    }
  }, [search, stops, routes, replace]);

  // TODO:
  // Logica de camión seleccionado: Seguir automaticamente al camión.
  // Icono de color azul al estar seleccionado.

  /** ***************************************** */
  /** Render */
  /** ***************************************** */
  const mainMap = (
    <MainMap // This is your custom component, not the library one.
      city={city}
      cities={cities}
      route={route}
      bus={bus}
      busses={busses}
      onBusClick={handleBussClick}
      tripRoute={trip.routes[trip.selectedTrip]}
      showPOIs={showPOIs}
      stops={stops}
      showBusses={showBusses}
      onStopClick={stop => {
        setStop(stop);
        toggleInfoStop();
      }}
      onPOIClick={poi => {
        setPoi(poi);
        toggleInfoPOI();
      }}
      onRPClick={rp => {
        setRP(rp);
        toggleInfoRP();
      }}
    >
      <MapButtons
        disableButtons={showSidebar || showRoutesSidebar}
        buttons={[
          {
            label: <FontAwesomeIcon icon={faCrosshairs} />,
            class: 'btn btn-light mirai-shadow',
            onClick: handleLocation,
          },
          ...(route.group_id
            ? [
              {
                label: <FontAwesomeIcon icon={faBuilding} />,
                class: `btn ${showPOIs ? 'btn-primary' : 'btn-secondary'} mirai-shadow`,
                title: 'Mostrar puntos de interes',
                onClick: toggleShowPOIs,
              },
            ]
            : []),
          ...(route.group_id
            ? [
              {
                label: <FontAwesomeIcon icon={faBus} />,
                class: `btn ${showBusses ? 'btn-primary' : 'btn-secondary'} mirai-shadow`,
                title: 'Mostrar monorrieles',
                onClick: toggleShowStops,
              },
            ]
            : []),
          ...(route.group_id
            ? [
              {
                label: <FontAwesomeIcon icon={faInfoCircle} />,
                class: 'btn btn-info mirai-shadow',
                onClick: toggleInfoRoute,
              },
            ]
            : []),
          {
            label: (
              <React.Fragment>
                <FontAwesomeIcon icon={faBus} style={{ marginRight: '0.5rem' }} />
                {route.group_id ? <span>{route.name}</span> : <span>Ver una línea</span>}
              </React.Fragment>
            ),
            class: `btn btn-une mirai-shadow btn-selectroute ${route.group_id && 'btn-routeselected'}`,
            onClick: toggleRoutesSidebar,
            fullWidth: isMobile,
          },
        ]}
      />
    </MainMap>
  );

  const mapLeftSide = [
    <div key="mapsidebar" className={styles['left']}>
      <MapSidebar show={showSidebar} close={toggleSidebar} />
    </div>,
    <div key="maproutessidebar" className={styles['left']}>
      <MapRoutesSidebar
        show={showRoutesSidebar}
        close={toggleRoutesSidebar}
        routes={routes}
        onRouteClick={onRouteClick}
        activeRoute={route.group_id}
        clearRoute={handleRouteClear}
      />
    </div>,
  ];

  if (!city) {
    return <Redirect to="/" />;
  }

  return (
    <React.Fragment>
      <BrowserView>
        <div data-testid="MapView" className={`card mirai-shadow ${styles['browser-card']} ${isIFrame() && styles['iframe']}`}>
          <div className={`card-body mirai-shadow ${styles['browser-card-header']}`}>
            <MapCardHeader
              onBtnClick={toggleSidebar}
              disableButtons={showSidebar || showRoutesSidebar}
              title={selectedCity ? selectedCity.name : 'Ciudad'}
              subtitle={route.name ? route.name : 'Seleccione línea'}
            />
          </div>
          <div className={styles['card-map']}>
            <LoadingBar show color="#0057B8" />
            {mapLeftSide}
            <div className={styles['right']}>{mainMap}</div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div data-testid="MapView">
          <div className={styles['card-map-mobile']}>
            {mapLeftSide}
            <div className={styles['right']}>
              <button
                type="button"
                className={`btn btn-light mirai-shadow ${styles['floating-button']}`}
                disabled={showSidebar || showRoutesSidebar}
                onClick={toggleSidebar}
              >
                <FontAwesomeIcon icon={faBars} />
              </button>
              {mainMap}
            </div>
          </div>
        </div>
      </MobileView>
      {/** Modales. */}
      <InfoRouteModal showModal={showInfoRoute} toggleModal={toggleInfoRoute} route={route} />
      <InfoBusModal
        showModal={showInfoBus}
        toggleModal={handleBusClose}
        bus={bus}
        routeTwoWays={routeTwoWays}
      />
      <TermsModal showModal={showTerms} toggleModal={toggleTerms} onAccept={acceptTerms} />
      <InfoStopModal showModal={showInfoStop} toggleModal={handleStopClose} stop={stop} routes={routes} route={route} />
      <InfoPOIModal showModal={showInfoPOI} toggleModal={handlePOIClose} poi={poi} />
      <InfoRPModal showModal={showInfoRP} toggleModal={handleRPClose} rp={rp} />
    </React.Fragment>
  );
};

MapView.propTypes = {
  terms: PropTypes.bool,
  acceptTerms: PropTypes.func,
  city: PropTypes.string,
  cities: PropTypes.array,
  routes: PropTypes.array,
  trip: PropTypes.shape({
    origin: PropTypes.any,
    destination: PropTypes.any,
    routes: PropTypes.array,
    selectedTrip: PropTypes.number,
  }),
  updateRoutes: PropTypes.func,
  clearTrip: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  changeCity: PropTypes.func,
};

MapView.defaultProps = {
  terms: false,
  acceptTerms: f => f,
  city: '1',
  cities: [],
  routes: [],
  trip: {
    origin: null,
    destination: null,
    routes: [],
    selectedTrip: 0,
  },
  updateRoutes: f => f,
  clearTrip: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
  changeCity: f => f,
};

const ConnectedMapView = reduxConnect(
  state => ({
    terms: state.terms,
    city: state.city,
    cities: state.cities,
    routes: state.routes,
    trip: state.trip,
  }),
  dispatch => ({
    updateRoutes: routes => dispatch(actions.updateRoutes(routes)),
    acceptTerms: () => dispatch(actions.acceptTerms(true)),
    clearTrip: () => dispatch(actions.tripClearTrip()),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
    changeCity: city => dispatch(actions.changeCity(city)),
  }),
)(MapView);

const mapLayout = LayoutOnWidth(ConnectedMapView, {
  browser: {
    backgroundStyle: {
      backgroundColor: '#f5f5f5',
      height: '100vh',
    },
    containerStyle: {
      height: '100%',
      alignItems: 'center',
    },
    rowStyle: {
      height: '100%',
      alignItems: 'center',
    },
    rowClass: 'row justify-content-center',
    colClass: 'col-12',
  },
});

export default AlertsHOC(mapLayout);
