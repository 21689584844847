import React from 'react';
import PropTypes from 'prop-types';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { Switch, Route, Link } from 'react-router-dom';
import StationsMap from './stationsMap/stationsMap';
import NewStation from './newStation/newStation';
import useUpdateCities from '#hooks/useUpdateCities';

const Stations = ({ match, updateCities, loading }) => {
  useUpdateCities(updateCities, loading, false);

  return (
    <div data-testid="Notificaciones">
      <div className="section-header">
        <h2 className="title">Estaciones</h2>
      </div>

      <div className="card">
        <div className="card-body">
          <Switch>
            <Route path={`${match.url}/estacion/:id`} component={NewStation} />
            <Route path={`${match.url}/estacion`} component={NewStation} />
            <Route path={`${match.url}`} component={StationsMap} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

Stations.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  updateCities: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

Stations.defaultProps = {
  match: {},
  updateCities: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(Stations);
