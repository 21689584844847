import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { isMobile, isSafari } from 'react-device-detect';
import { connect as reduxConnect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox, faSignInAlt, faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import useClickOutside from '#hooks/useClickOutside';
import styles from './mapSidebar.module.css'
import { faMap } from '@fortawesome/free-regular-svg-icons';
import { actions } from '#redux/reducers';

const logo = require('#assets/images/logo.png');

const MapSidebar = ({ show, close, showLogo, forceMinWidth }) => {
  const clickRef = useClickOutside(show, close);

  return (
    <div data-testid="MapSidebar" ref={clickRef} className={`${styles['sidebar']} ${!show && styles['show']} ${isSafari && styles['safari']} ${forceMinWidth && styles['minwidth']}`}>
      <div className={styles['header']}>
        {(isMobile || showLogo) && (
          <img src={logo} alt="Logo" className={styles['header-logo']} />
        )}
      </div>
      <div className={styles['body']}>
        <ul className={styles['body-list']}>
          <li>
            <NavLink to="/notificaciones" activeClassName={styles['active']}>
              <FontAwesomeIcon icon={faInbox} />
              <span>Notificaciones</span>
            </NavLink>
          </li>
          <li>
            <a href="https://api.whatsapp.com/send?phone=526621047755&text=Buen%20d%C3%ADa,%20deseo%20realizar%20un%20reporte" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} />
              <span>Atención</span>
            </a>
          </li>
          <li>
            <NavLink to="/estaciones" activeClassName={styles['active']}>
              <FontAwesomeIcon icon={faMap} />
              <span>Mapa</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

MapSidebar.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func,
  showLogo: PropTypes.bool,
  forceMinWidth: PropTypes.bool,
  clearTrip: PropTypes.func,
};

MapSidebar.defaultProps = {
  show: false,
  close: f => f,
  showLogo: false,
  forceMinWidth: false,
  clearTrip: f => f,
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    clearTrip: () => dispatch(actions.tripClearTrip()),
  }),
)(MapSidebar);
