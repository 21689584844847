import React from 'react';
import ReactDomServer from 'react-dom/server';
import Leaflet from 'leaflet';

const iconList = {
  default: require('#assets/images/pines/icon_default.png'),
  bus_default: require('#assets/images/pines/icon_bus_default.png'),
  bus_default_alt: require('#assets/images/pines/icon_bus_default_alt.png'),
  bus_default_selected: require('#assets/images/pines/icon_bus_default_selected.png'),
  bus_stop: require('#assets/images/pines/icon_bus_stop.png'),
  bus_stop_selected: require('#assets/images/pines/icon_bus_stop_selected.png'),
  bus_stop_collision: require('#assets/images/pines/icon_bus_stop_collision.png'),
  bus_stop_gray: require('#assets/images/pines/icon_bus_stop_gray.png'),
  bus_stop_green: require('#assets/images/pines/icon_bus_stop_green.png'),
  user: require('#assets/images/pines/icon_user.png'),
  arrow: require('#assets/images/pines/icon_arrow.png'),
  handicap: require('#assets/images/pines/icon_handicap.png'),
  poi: require('#assets/images/pines/icon_poi.png'),
  recharge_location: require('#assets/images/pines/icon_recharge_location.png'),
  credential_location: require('#assets/images/pines/icon_credential_location.png'),
  flag: require('#assets/images/pines/icon_flag.png'),
  target: require('#assets/images/pines/icon_target.png'),
  stop: require('#assets/images/pines/icon_stop.png'),
  ball: require('#assets/images/pines/icon_ball.png'),
  ball_transparent: require('#assets/images/pines/icon_ball_transparent.png'),
  logo: require('#assets/images/logo.png'),
};

export function generateMarker({
  className = 'marker',
  iconSize = [41, 47],
  iconAnchor = [18, 40],
  alt = 'Marker',
  icon = 'default',
}) {
  return Leaflet.divIcon({
    className,
    iconSize,
    iconAnchor,
    html: ReactDomServer.renderToString(
      <div>
        <img src={iconList[icon]} alt={alt} className="simple-marker" />
      </div>,
    ),
  });
}

export function generateBusMarker(
  bus,
  {
    className = 'busMarker',
    iconSize = [41, 80],
    iconAnchor = [18, 70],
    icon = 'bus_default',
    alt = 'Unidad',
    icon_url = false,
  },
) {
  const rotation = { transform: `rotate(${bus.cape}deg)` };
  return Leaflet.divIcon({
    className,
    iconSize,
    iconAnchor,
    html: ReactDomServer.renderToString(
      <div>
        <img src={iconList['arrow']} alt="Dirección" className="arrow-marker" style={rotation} />
        <img src={icon_url ? icon : iconList[icon]} alt={alt} className="bus-marker" />
      </div>,
    ),
  });
}

export function generatePBallTMarker() {
  return Leaflet.icon({
    className: 'mapBall',
    iconSize: [20, 20],
    iconAnchor: [10, 10],
    iconUrl: iconList['ball_transparent'],
  });
}

export function generatePBallMarker() {
  return Leaflet.icon({
    className: 'mapBall',
    iconSize: [15, 15],
    iconAnchor: [7, 7],
    iconUrl: iconList['ball'],
  });
}
