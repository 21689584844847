import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRandom, faCog, faEyeSlash, faFileCsv, faBus } from '@fortawesome/free-solid-svg-icons';
import TabsCard from '#components/UI/tabsCard/tabsCard';
import TableBussesConfiguration from '#components/tables/busses/tableBussesConfiguration';
import TableBussesAsign from '#components/tables/busses/tableBussesAsign';
import tableBussesHide from '#components/tables/busses/tableBussesHide';
import TableBussesStatus from '#components/tables/busses/tableBussesStatus';

const Busses = () => (
  <TabsCard
    title="Control de monorrieles"
    tabs={[
      {
        exact: true,
        to: '/panel/monorrieles/configuracion',
        render: (
          <>
            <FontAwesomeIcon icon={faCog} style={{ marginRight: '.5rem' }} />
            Configuración de monorrieles
          </>
        ),
      },
      {
        exact: true,
        to: '/panel/monorrieles/asignacion',
        render: (
          <>
            <FontAwesomeIcon icon={faRandom} style={{ marginRight: '.5rem' }} />
            Asignación de monorrieles
          </>
        ),
      },
      {
        exact: true,
        to: '/panel/monorrieles/ocultar',
        render: (
          <>
            <FontAwesomeIcon icon={faEyeSlash} style={{ marginRight: '.5rem' }} />
            Ocultar monorrieles
          </>
        ),
      }/*,
      {
        exact: true,
        to: '/panel/monorrieles/estado',
        render: (
          <>
            <FontAwesomeIcon icon={faFileCsv} style={{ marginRight: '.5rem' }} />
            Estado de monorrieles
          </>
        ),
      },*/
    ]}
    routes={[
      <Route exact key="/panel/monorrieles/configuracion" path="/panel/monorrieles/configuracion" component={TableBussesConfiguration} />,
      <Route exact key="/panel/monorrieles/asignacion" path="/panel/monorrieles/asignacion" component={TableBussesAsign} />,
      <Route exact key="/panel/monorrieles/ocultar" path="/panel/monorrieles/ocultar" component={tableBussesHide} />,
      <Route exact key="/panel/monorrieles/estado" path="/panel/monorrieles/estado" component={TableBussesStatus} />,
      <Redirect key="/panel/monorrieles" from="/panel/monorrieles" to="/panel/monorrieles/configuracion" />,
    ]}
  />
);

Busses.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Busses.defaultProps = {
  match: {},
};

export default Busses;
