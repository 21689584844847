import React from 'react';
import PropTypes from 'prop-types';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';
import { WafoForm, WafoFormInput, WafoFormSelect, WafoFormTextArea } from '@wafo/forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const FormUser = ({ history, match: { params }, location, loading }) => {
  const [user, setUser] = React.useState(() => {
    if (location.state && location.state.user) {
      return location.state.user;
    }
    return {};
  });

  const handleFormsubmit = async (form, values) => {
    if (form.valid) {
      try {
        loading.set();
        const user = {
          name: values.name,
          last_name: values.last_name,
          email: values.email,
          ...(values.password ? { password: values.password } : {})
        };
        const res = params.id ? await roAPI.put(`/admin/users/${params.id}`, user) : await roAPI.post('/admin/users', user);
        setUser(prevUser => ({ ...prevUser, ...res }));
        history.push('/panel/users');
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <button type="button" className={`btn btn-link back`} onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
          <span>Regresar</span>
        </button>
      </div>
      <div className="col-12">
        <WafoForm key={params.id} formId="formUser" locale="es" onSubmit={handleFormsubmit} values={user}>
          <WafoFormInput type="text" name="name" label="Nombre" placeholder="Nombre" customClass="col-12 col-md-4" />

          <WafoFormInput
            type="text"
            name="last_name"
            label="Apellidos"
            placeholder="Apellidos"
            customClass="col-12 col-md-4"
          />

          <WafoFormInput
            type="email"
            name="email"
            label="Correo electrónico"
            placeholder="Correo electrónico"
            customClass="col-12 col-md-4"
            validations={{
              email: true,
            }}
          />

          <WafoFormInput
            type="password"
            name="password"
            placeholder="Contraseña"
            label="Contraseña"
            customClass="col-12 col-md-4"
            validations={{ required: params.id ? false : true, maxLength: 100 }}
          />
        </WafoForm>
        <button type="submit" form="formUser" className={`btn btn-une btn-submit btn-submit-center`}>
          Guardar cambios
        </button>
      </div>
    </div>
  );
};

FormUser.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.any,
  }),
  location: PropTypes.shape({
    state: PropTypes.any,
  }),
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

FormUser.defaultProps = {
  history: {
    goBack: f => f,
    push: f => f,
  },
  match: {
    params: {},
  },
  location: {
    state: {},
  },
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(FormUser);
