import React from 'react';
import jwt from 'jsonwebtoken';
import { BrowserView, MobileView } from 'react-device-detect';
import { Switch, Route, useHistory } from 'react-router-dom';
import AlertsHOC from '#components/HOCs/alertsHOC/alertsHOC';
import FormLogin from '#components/forms/formLogin/formLogin';
import { getVerifiedToken } from '#utils/authService';
import styles from './login.module.css';

const logo = require('#assets/images/logo.png');

const Login = () => {
  const history = useHistory();

  const handleRedirects = React.useCallback((value, type = 'token') => {
    switch (type) {
      case 'token': {
        const decoded = jwt.decode(value);
        if ([1, 3].includes(decoded.type)) {
          history.push('/panel');
        } else if (![1, 3].includes(decoded.type)) {
          history.push('/');
        }
        break;
      }
      case 'redirect':
        history.push(value);
        break;
      default:
        break;
    }
  }, [history]);

  React.useEffect(() => {
    async function checkToken() {
      const verified = await getVerifiedToken();
      if (verified) {
        handleRedirects(verified.token);
      }
    }
    checkToken();
  }, [handleRedirects]);

  const SubtitleComponent = (
    <Switch>
      <Route path="/auth/inicio" render={() => <h1 className="text-center">¡Bienvenido de vuelta!</h1>} />
    </Switch>
  );

  const FormsComponent = (
    <Switch>
      <Route path="/auth/inicio" render={() => <FormLogin onAfterSubmit={handleRedirects} />} />
    </Switch>
  );

  return (
    <div className={styles['login']}>
      <BrowserView>
        <div className={`container ${styles['height100']} ${styles['padding']}`}>
          <div className={`row justify-content-center align-items-center ${styles['height100']}`}>
            <div className="col-12 col-sm-12 col-md-6">
              <div className="card mirai-shadow">
                <div className={`card-body ${styles['card-body']}`}>
                  <img src={logo} alt="Logo" className={styles['logo']} />

                  {SubtitleComponent}

                  {FormsComponent}

                </div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className={`card ${styles['mobile-card']}`}>
          <div className={`card-body mirai-shadow ${styles['mobile-body']}`}>
            <img src={logo} alt="Logo" className={styles['logo']} />
            {SubtitleComponent}
          </div>
          <div className={styles['otro-body']}>{FormsComponent}</div>
        </div>
      </MobileView>
    </div>
  );
};

export default AlertsHOC(Login);
