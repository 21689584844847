import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import PanelNavbar from '#components/navbars/panelNavbar/panelNavbar';
import PanelSidebar from '#components/navbars/panelSidebar/panelSidebar';
import Monitor from './monitor/monitor';
import Notifications from './notifications/notifications';
import Cities from './cities/cities';
import Lines from './lines/lines';
import Stations from './stations/stations';
import AlertList from '#components/alerts/alertList/alertList';
import Pois from './pois/pois';
import LoadingBar from '#components/loading/loadingBar/loadingBar';
import Busses from './busses/busses';
import styles from './panel.module.css';
import Users from './users/users';
import PrivateRoute from '#components/router/privateRoute';
import Configurations from './configurations/configurations';

const Panel = ({ match, toggled, toggleSidebar }) => {
  return (
    <div data-testid="Panel">
      <div className={styles.header}>
        <PanelNavbar onToggleClick={() => toggleSidebar(!toggled)} />
      </div>
      <div className={styles.body}>
        <LoadingBar show color="#0057B8" />
        <div className={styles.left}>
          <PanelSidebar toggled={toggled} />
        </div>
        <div className={styles.right}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <AlertList />
                <Switch>
                  <Route exact path={`${match.url}/`} render={() => <Redirect to={`${match.url}/monitor`} />} />
                  <Route path={`${match.url}/monitor`} component={Monitor} />
                  <Route path={`${match.url}/notificaciones`} component={Notifications} />
                  <Route path={`${match.url}/ciudades`} component={Cities} />
                  <Route path={`${match.url}/lineas`} component={Lines} />
                  <Route path={`${match.url}/estaciones`} component={Stations} />
                  <Route path={`${match.url}/pois`} component={Pois} />
                  <Route path={`${match.url}/monorrieles`} component={Busses} />
                  <PrivateRoute path={`${match.url}/users`} component={Users} allowed={[1]} />
                  <PrivateRoute path={`${match.url}/configuracion`} allowed={[1]} component={Configurations} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Panel.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  toggled: PropTypes.bool,
  toggleSidebar: PropTypes.func,
};

Panel.defaultProps = {
  match: {},
  toggled: false,
  toggleSidebar: f => f,
};

export default reduxConnect(
  state => ({
    toggled: state.panelsidebarToggle,
  }),
  dispatch => ({
    toggleSidebar: sidebar => dispatch(actions.togglePanelsidebar(sidebar)),
  }),
)(Panel);
