import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Map, TileLayer, Polyline, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { decode } from '@mapbox/polyline';
import { boundsOfCity } from '#utils/utils';
import { generateMarker, generateBusMarker } from '#components/maps/extras/markers/markers';
import styles from './mainMap.module.css';

const busStopIcon = generateMarker({ icon: 'logo', iconSize: [25, 25], iconAnchor: [15, 15] });
const pointOfInterestIcon = generateMarker({ icon: 'poi' });
const userIcon = generateMarker({ icon: 'user' });
const walkingIcon = generateMarker({ icon: 'flag' });
const rpIcon = generateMarker({ icon: 'recharge_location' });
const rpcIcon = generateMarker({ icon: 'credential_location' });

const MainMap = ({
  city,
  cities,
  route,
  bus,
  busses,
  onBusClick,
  tripRoute,
  children,
  showPOIs,
  onPOIClick,
  stops,
  showBusses,
  onStopClick,
  onRPClick,
  routeConfigs,
}) => {
  const [map, setMap] = React.useState(() => {
    const { center, bounds } = boundsOfCity(cities, city);
    return {
      zoom: 12,
      position: center,
      bounds,
    };
  });
  const [zoomAllow, setZoomAllow] = React.useState(false);

  React.useEffect(() => {
    const { center, bounds } = boundsOfCity(cities, city);
    setMap({
      position: center,
      zoom: 12,
      bounds,
    });
  }, [city, cities]);

  const mapRef = React.useRef(null);

  // Set bounds when a route is selected.
  const polylineRef = React.useRef(null);
  const polylineTripRef = React.useRef(null);

  const resetBounds = React.useCallback(() => {
    if (tripRoute && polylineTripRef.current) {
      mapRef.current.leafletElement.fitBounds(polylineTripRef.current.leafletElement.getBounds());
    } else if (route.group_id && polylineRef.current) {
      // reset bounds to route
      mapRef.current.leafletElement.fitBounds(polylineRef.current.leafletElement.getBounds());
    } else if (cities.length) {
      // reset bounds to city
      mapRef.current.leafletElement.fitBounds(boundsOfCity(cities, city).bounds);
    }
  }, [tripRoute, city, cities, route]);

  React.useEffect(() => {
    resetBounds();
    if (mapRef.current) {
      mapRef.current.leafletElement.on('zoomend', ({ target: { _zoom } }) => {
        setZoomAllow(_zoom >= 14);
      });
    }
  }, [route, tripRoute, resetBounds]);

  React.useEffect(() => {
    if (bus && bus.id) {
      // const currentZoom = mapRef.current.leafletElement.getZoom();
      // mapRef.current.leafletElement.flyTo([bus.latitude, bus.longitude], currentZoom > 13 ? currentZoom : 13);
      mapRef.current.leafletElement.flyTo([bus.latitude, bus.longitude], 16);
    }
  }, [bus]);

  const handleStopClick = stop => {
    const currentZoom = mapRef.current.leafletElement.getZoom();
    mapRef.current.leafletElement.flyTo([stop.latitude, stop.longitude], currentZoom > 12 ? currentZoom : 13);
    onStopClick(stop);
  };

  const handlePOIClick = poi => {
    const currentZoom = mapRef.current.leafletElement.getZoom();
    mapRef.current.leafletElement.flyTo([poi.latitude, poi.longitude], currentZoom > 12 ? currentZoom : 13);
    onPOIClick(poi);
  };

  const handleRPClick = rp => {
    const currentZoom = mapRef.current.leafletElement.getZoom();
    mapRef.current.leafletElement.flyTo([rp.latitude, rp.longitude], currentZoom > 12 ? currentZoom : 13);
    onRPClick(rp);
  };

  // Decoding the line string. Memoized so we don't re-parse on every busses update.
  // const decodedRoute = decodeSRID(route.lines);
  const memoizedDecodedRoute = React.useMemo(() => {
    let decodedRoute = decode(route.polyline || '');
    if (decodedRoute) {
      // decodedRoute = decodedRoute.map(cords => [cords[1], cords[0]]);
      return decodedRoute;
    }
    return [];
  }, [route]);

  const memoizedDecodedChilds = React.useMemo(() => {
    const childs = [];
    if (route.route_unions && route.route_unions.length) {
      route.route_unions.forEach(rc => {
        const child = {
          points: [],
          route_configurations: rc.route_configurations,
          primary_color: rc.primary_color,
          secondary_color: rc.secondary_color,
        };
        const decodedChild = decode(rc.polyline || '');
        if (decodedChild) {
          child.points = decodedChild;
        }
        childs.push(child);
      });
    }
    return childs;
  }, [route]);

  const memoTripRoute = React.useMemo(() => {
    const decoded = {
      complete: [],
      inicial: [],
      final: [],
      recorridoA: [],
      recorridoB: [],
      intermedio: [],
    };
    if (tripRoute) {
      decoded.inicial = decode(tripRoute.inicial.polyline || '');
      decoded.final = decode(tripRoute.final.polyline || '');
      decoded.recorridoA = decode(tripRoute.recorridoA.recorrido.polyline || '');
      if (tripRoute.type === 'complex') {
        decoded.intermedio = decode(tripRoute.intermedio.polyline || '');
        decoded.recorridoB = decode(tripRoute.recorridoB.recorrido.polyline || '');
      }
      decoded.complete = [...decoded.inicial, ...decoded.recorridoA, ...decoded.intermedio, ...decoded.recorridoB, ...decoded.final];
    }
    return decoded;
  }, [tripRoute]);

  return (
    <div data-testid="MainMap" className={styles['map-wrapper']}>
      <Map
        ref={mapRef}
        center={map.position}
        zoom={map.zoom}
        zoomControl={!isMobile}
        maxZoom={18}
        animate
        bounds={map.bounds}
        className={styles['leaflet-container']}
        onClick={() => onBusClick({}, false)}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/** Bus route polyline and markers */}
        {memoizedDecodedRoute.length !== 0 && (
          <Polyline ref={polylineRef} positions={memoizedDecodedRoute} weight={4} opacity={tripRoute ? 0.5 : 1} color={route.primary_color} />
        )}
        {memoizedDecodedChilds.length &&
          memoizedDecodedChilds.map((child, i) => <Polyline key={i} positions={child.points} weight={4} opacity={tripRoute ? 0.5 : 1} color={child.primary_color} />)}
        {showBusses &&
          zoomAllow &&
          busses.map(b => {
            let icon = null;

            if (b.hide) return null;

            if (bus && b.id === bus.id) {
              // TODO: Cambiar por icono de selected desde back u otra solución que no implique icono.
              icon = generateBusMarker(b, {
                icon: 'bus_default_selected',
              });
            } else if (b.configurations && b.configurations.length) {
              // TODO: Los sort aquí estan demasiado pesados?
              const order = b.configurations.sort((a, b) => a.order - b.order);
              icon = generateBusMarker(b, {
                alt: order[0].name,
                icon: `${process.env.REACT_APP_IMG_BUSICON}/${order[0].image_icon}`,
                icon_url: true,
              });
            } else if (b.on_child_route && routeConfigs.child.length) {
              icon = generateBusMarker(b, {
                alt: routeConfigs.child[0].name,
                icon: `${process.env.REACT_APP_IMG_BUSICON}/${routeConfigs.child[0].image_icon}`,
                icon_url: true,
              });
            } else if (routeConfigs.parent.length) {
              icon = generateBusMarker(b, {
                alt: routeConfigs.parent[0].name,
                icon: `${process.env.REACT_APP_IMG_BUSICON}/${routeConfigs.parent[0].image_icon}`,
                icon_url: true,
              });
            } else {
              icon = generateBusMarker(b, { icon: 'bus_default_selected' });
            }

            return (
              <Marker
                key={b.code}
                position={[b.latitude, b.longitude]}
                icon={icon}
                title={b.code}
                onClick={() => onBusClick(b)}
                zIndexOffset={1000}
              />
            );
          })}
        {zoomAllow &&
          showPOIs &&
          route.route_pois &&
          route.route_pois.map(point => (
            <Marker
              key={point.id_poi}
              position={[point.latitude, point.longitude]}
              icon={pointOfInterestIcon}
              title={point.name}
              onClick={() => handlePOIClick(point)}
            />
          ))}
        {stops.map(stop => (
          <Marker
            key={stop.codigo}
            position={[stop.latitude, stop.longitude]}
            icon={busStopIcon}
            title={stop.name}
            onClick={() => handleStopClick(stop)}
          />
        ))}
        {/** Trip route polyline and markers */}
        <Polyline ref={polylineTripRef} positions={memoTripRoute.complete} color="transparent" />
        <Polyline positions={memoTripRoute.inicial} color="#0CAFB7" weight={6} />
        <Polyline positions={memoTripRoute.recorridoA} color="#FF821B" weight={6} />
        <Polyline positions={memoTripRoute.intermedio} color="#0CAFB7" weight={6} />
        <Polyline positions={memoTripRoute.recorridoB} color="#FF821B" weight={6} />
        <Polyline positions={memoTripRoute.final} color="#0CAFB7" weight={6} />
        {tripRoute && tripRoute.recorridoA && tripRoute.recorridoA.subida && (
          <>
            <Marker position={[memoTripRoute.inicial[0][0], memoTripRoute.inicial[0][1]]} icon={userIcon} title="Punto inicial" />
            <Marker position={[memoTripRoute.final[0][0], memoTripRoute.final[0][1]]} icon={walkingIcon} title="Punto final" />
            <Marker
              position={[tripRoute.recorridoA.subida.latitude, tripRoute.recorridoA.subida.longitude]}
              icon={busStopIcon}
              title={tripRoute.recorridoA.subida.codigo}
            />
            <Marker
              position={[tripRoute.recorridoA.bajada.latitude, tripRoute.recorridoA.bajada.longitude]}
              icon={busStopIcon}
              title={tripRoute.recorridoA.bajada.codigo}
            />
          </>
        )}
        {tripRoute && tripRoute.recorridoB && tripRoute.recorridoB.subida && (
          <>
            <Marker
              position={[tripRoute.recorridoB.subida.latitude, tripRoute.recorridoB.subida.longitude]}
              icon={busStopIcon}
              title={tripRoute.recorridoB.subida.codigo}
            />
            <Marker
              position={[tripRoute.recorridoB.bajada.latitude, tripRoute.recorridoB.bajada.longitude]}
              icon={busStopIcon}
              title={tripRoute.recorridoB.bajada.codigo}
            />
          </>
        )}
      </Map>
      {React.Children.map(children, child => React.cloneElement(child, { map: { ref: mapRef.current, resetBounds } }))}
    </div>
  );
};

MainMap.propTypes = {
  city: PropTypes.string,
  cities: PropTypes.array,
  route: PropTypes.any,
  bus: PropTypes.any,
  busses: PropTypes.array,
  onBusClick: PropTypes.func,
  tripRoute: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  showPOIs: PropTypes.bool,
  onPOIClick: PropTypes.func,
  stops: PropTypes.array,
  showStops: PropTypes.bool,
  onStopClick: PropTypes.func,

  onRPClick: PropTypes.func,
  routeConfigs: PropTypes.shape({
    parent: PropTypes.any,
    child: PropTypes.any,
  }),
};

MainMap.defaultProps = {
  city: '1',
  cities: [],
  route: {
    points_of_interest: [],
  },
  bus: null,
  busses: [],
  onBusClick: f => f,
  tripRoute: null,
  children: null,
  showPOIs: false,
  onPOIClick: f => f,
  stops: [],
  showStops: true,
  onStopClick: f => f,
  onRPClick: f => f,
  routeConfigs: {
    parent: [],
    child: [],
  },
};

export default MainMap;
